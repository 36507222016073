import { createRouter, createWebHistory } from "vue-router";

const routes = [
    { path: "/", redirect: "/login" },
    {
        path: "/login",
        name: "login",
        component: () =>
            import ("../page/Login.vue"),
        meta: { title: "登录" },
    },
    {
        path: "/entry",
        name: "entry",
        redirect: "/home",
        component: () =>
            import ("../page/Entry.vue"),
        children: [{
                path: "/home",
                name: "home",
                component: () =>
                    import ("../page/Home.vue"),
                meta: { title: "小区概览" },
            },
            {
                path: "/household",
                name: "household",
                component: () =>
                    import ("../page/HouseholdData.vue"),
                meta: { title: "住户数据" },
            },
            {
                path: "/warn",
                name: "warn",
                component: () =>
                    import ("../page/WarningInfo.vue"),
                meta: { title: "预警信息" },
            },
            {
                path: "/history",
                name: "history",
                component: () =>
                    import ("../page/History.vue"),
                meta: { title: "历史数据" },
            },
        ],
    },
];

export const router = createRouter({
    history: createWebHistory(),
    routes,
});