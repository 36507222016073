import axios from "axios";

let config = {
  baseURL: process.env.baseURL || process.env.apiUrl || "",
  timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default {
  install: function (app) {
    // 添加全局的方法
    app.config.globalProperties.axios = _axios;
    // 添加全局的方法
    app.config.globalProperties.$translate = (key) => {
      return key;
    };
  },
};
